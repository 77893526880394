import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { AngularMaterialModule } from '../../../shared/modules/angular-material/angular-material.module';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';

export interface PeriodicElement {
  accountname: string;
  Address: string;
  accountmanager: string;
  contractstart: string;
  contractreview: string;
  clientSPOC: string;
  currency: string;
  lastupdated: string;
  action: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  { accountname: 'Blackwoods', Address: '94 South Jade Court Mahopac, NY, US', accountmanager: 'Arlene McCoy', contractstart: '19 Aug 2023', contractreview: '19 Aug 2024', clientSPOC: '--', currency: 'USD', lastupdated: '27 Oct 2022 11:54 AM', action: '' },
  { accountname: 'Blackwoods', Address: '8502 Preston Rd. Inglewood, Maine 98380', accountmanager: 'Arlene McCoy', contractstart: '19 Aug 2023', contractreview: '19 Aug 2024', clientSPOC: '--', currency: 'USD', lastupdated: '27 Oct 2022 11:54 AM', action: '' },
  { accountname: 'Blackwoods', Address: '2464 Royal Ln. Mesa, New Jersey 45463', accountmanager: 'Arlene McCoy', contractstart: '19 Aug 2023', contractreview: '19 Aug 2024', clientSPOC: '--', currency: 'USD', lastupdated: '27 Oct 2022 11:54 AM', action: '' },
  { accountname: 'Blackwoods', Address: '1901 Thornridge Cir. Shiloh, Hawaii 81063', accountmanager: 'Arlene McCoy', contractstart: '19 Aug 2023', contractreview: '19 Aug 2024', clientSPOC: '--', currency: 'USD', lastupdated: '27 Oct 2022 11:54 AM', action: '' },
  { accountname: 'Blackwoods', Address: '6391 Elgin St. Celina, Delaware 10299', accountmanager: 'Arlene McCoy', contractstart: '19 Aug 2023', contractreview: '19 Aug 2024', clientSPOC: '--', currency: 'USD', lastupdated: '27 Oct 2022 11:54 AM', action: '' },
 


];
@Component({
  selector: 'app-action-table',
  standalone: true,
  imports: [MatFormFieldModule, MatInputModule, FormsModule, MatButtonModule, MatIconModule, AngularMaterialModule, MatTableModule, MatPaginatorModule],
  templateUrl: './action-table.component.html',
  styleUrl: './action-table.component.css'
})
export class ActionTableComponent {
  displayedColumns: string[] = ['accountname', 'Address', 'accountmanager', 'contractstart', 'contractreview', 'clientSPOC', 'currency', 'lastupdated', 'action'];
  dataSource = ELEMENT_DATA;
}
