<div class="body-bg table-container">
  <table mat-table [dataSource]="dataSource" class="custom-action-table">
    <ng-container matColumnDef="accountname">
      <th mat-header-cell *matHeaderCellDef> Account Name </th>
      <td mat-cell *matCellDef="let element">
        <div class="d-flex align-center">
          <div class="table-avatar mr-2"><img src="/profile.jpg" alt="profile"></div>
          <span>{{element.accountname}}</span>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="Address">
      <th mat-header-cell *matHeaderCellDef> Address </th>
      <td mat-cell *matCellDef="let element"> {{element.Address}} </td>
    </ng-container>
    <ng-container matColumnDef="accountmanager">
      <th mat-header-cell *matHeaderCellDef> Account Manager </th>
      <td mat-cell *matCellDef="let element"> {{element.accountmanager}} </td>
    </ng-container>
    <ng-container matColumnDef="contractstart">
      <th mat-header-cell *matHeaderCellDef> Contract Start </th>
      <td mat-cell *matCellDef="let element"> {{element.contractstart}} </td>
    </ng-container>
    <ng-container matColumnDef="contractreview">
      <th mat-header-cell *matHeaderCellDef>Contract Review</th>
      <td mat-cell *matCellDef="let element"> {{element.contractreview}} </td>
    </ng-container>
    <ng-container matColumnDef="clientSPOC">
      <th mat-header-cell *matHeaderCellDef> Client SPOC </th>
      <td mat-cell *matCellDef="let element"> {{element.clientSPOC}} </td>
    </ng-container>
    <ng-container matColumnDef="currency">
      <th mat-header-cell *matHeaderCellDef> Currency </th>
      <td mat-cell *matCellDef="let element"> {{element.currency}} </td>
    </ng-container>
    <ng-container matColumnDef="lastupdated">
      <th mat-header-cell *matHeaderCellDef> Last Updated </th>
      <td mat-cell *matCellDef="let element"> {{element.lastupdated}} </td>
    </ng-container>
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef> Action </th>
      <td mat-cell *matCellDef="let element" class="text-center">
        <mat-icon [matMenuTriggerFor]="menu">
          more_vert
        </mat-icon>
      </td>
      <mat-menu #menu="matMenu">
        <button mat-menu-item>Edit</button>

      </mat-menu>
    </ng-container>
    <tr mat-header-row class="action-table-header-data" *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row class="action-table-body-data" *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator class="action-table-pagination w-full" [length]="100" [pageSize]="10"
    [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page">
  </mat-paginator>

</div>